import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import { setHTML } from './../../helpers'

import { getContents } from '../../selectors/contents'
import Parser from 'html-react-parser'

const mapStateToProps = (state, ownProps) => ({
  contents: getContents(state).data.find(content => content.permalink === ownProps.location.pathname.slice(1))
})

class Privacy extends Component {
  render() {
    const { contents } = this.props

    if(!contents) return null

    const { title, body } = contents

    return (
      <section className="page-section static-page">
        <div itemScope itemType="http://schema.org/NewsArticle" className="single-content">
          <Grid className={ "no-gutter" } fluid>
            <Row>
              <Col sm={7} smOffset={4}>
                <h1 itemProp="headline">{title}</h1>
              </Col>
            </Row>
            <Row>
              <Col sm={7} smOffset={4}>
                <Row>
                  <Col sm={12} className={'content'} itemProp="articleBody">
                    <div className="content">
                      { body && Parser(body) }
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </div>
      </section>
    )
  }
}

export default connect(mapStateToProps, null)(Privacy)
