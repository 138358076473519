import React, { Component } from "react"
import { Grid, Row, Col, Button, Glyphicon } from "react-bootstrap"
import Parser from "html-react-parser"
import ReactPlayer from "react-player"

import { MetaHelmet } from './MetaHelmet'

const toggleSideBar = (action) => {
  const app = document.querySelector('.app')
  const classNames = ['static-page', 'secondary-section']

  if(app.classList[action]){
    app.classList[action](...classNames)
  }
}

class StaticPage extends Component {
  state = {
    isStaticPagePlaying: false
  }

  componentDidMount(){
    toggleSideBar('add')
  }

  toggleVideo = () => {
   this.setState({
      isStaticPagePlaying: !this.state.isStaticPagePlaying
    })
  }

  render() {
    const {
      title,
      body,
      image,
      imageTitle,
      imageSubtitle,
      video,
      videoTitle,
      videoSubtitle,
      audio,
      audioTitle,
      audioSubtitle,
      attachments,
      metaTitle,
      metaDescription
    } = this.props

    return (
      <section className="page-section static-page">
        <MetaHelmet metaTitle={metaTitle} metaDescription={metaDescription}/>
        <Grid className={ "no-gutter" } fluid>
          <Row>
            <Col sm={7} smOffset={4}>
              <h1>{ title && Parser( title ) }</h1>
              {
                attachments && image && attachments[image.body] &&
                  <div className={ "image-wrap" }>
                    <img className={"img-responsive"} src={attachments[image.body].url.lg} alt={ title } />
                  </div>
              }
              <p className={"title"}>{ imageTitle && Parser( imageTitle.body ) }</p>
              <p className={"subtitle"}>{ imageSubtitle && Parser( imageSubtitle.body ) }</p>
            </Col>
          </Row>
          <Row>
            <Col sm={7} smOffset={4}>
              <Row>
                <Col sm={12}>
                  <div className="content">
                    { body && Parser(body) }
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          {
            video &&
            <Row>
              <Col sm={7} smOffset={4}>
                <div>
                  <div className="player-wrapper">
                    <ReactPlayer
                      url= { video.body }
                      className={ "react-player" }
                      width={ "100%" }
                      height={ "100%" }
                      playing={ this.state.isStaticPagePlaying }
                      config={{
                        youtube: {
                          playerVars: { modestbranding: 1 }
                        }
                      }}
                    />
                    <Button className = { "video-toggle bottom-left" } onClick={this.toggleVideo}>
                      <Glyphicon glyph={ this.state.isStaticPagePlaying ? "pause" : "play" } />
                    </Button>
                  </div>
                  <div className="video-description">
                    <p className={"title"}>{ videoTitle && Parser( videoTitle.body ) }</p>
                    <p className={"subtitle"}>{ videoSubtitle && Parser( videoSubtitle.body ) }</p>
                  </div>
                </div>
              </Col>
            </Row>
          }
          <br />
          {
            audio &&
            <Row>
              <Col sm={7} smOffset={4}>
                <div>
                  <div className="audio-player">
                    <audio controls>
                      <source src={ audio.body } type="audio/mpeg" />
                    </audio>
                  </div>
                  <div className="video-description">
                    <p className={"title"}>{ audioTitle && Parser( audioTitle.body ) }</p>
                    <p className={"subtitle"}>{ audioSubtitle && Parser( audioSubtitle.body ) }</p>
                  </div>
                  <br />
                </div>
              </Col>
            </Row>
          }
        </Grid>
      </section>
    )
  }
}

export default StaticPage
