import React, { Component } from 'react'
import { connect } from "react-redux";
import Parser from "html-react-parser"
import loghiSostenitori from "./../assets/images/sostentitori.svg"
import loghiSostenitori2 from './../assets/images/LOGHI2.svg'
import logoLeMaus from "./../assets/images/lemaus.svg"
import logoVentie30 from "./../assets/images/ventie30.svg"
import logoAlessioConti from "./../assets/images/alessioconti.svg"
import { getAside, getAttachmentUrl } from "../helpers/contents";

import {
  getAboutContents,
  getAboutSlideshowContents
} from './../selectors'
import Spinner from "./Spinner";
import { MetaHelmet } from "./MetaHelmet";

const mapStateToProps = state => ({
  contents: getAboutContents(state),
  getAboutSlideshowContents: getAboutSlideshowContents(state)
})

class AboutPage extends Component {

  render() {

    const { contents } = this.props

    const slide1Image = getAside(contents, 'slide1Image')

    const customStyle = {
      backgroundImage: 'url(' + getAttachmentUrl(contents.attachments && contents.attachments[slide1Image]) + ')'
    };

    if(!Object.keys(contents).length){
      return <Spinner customClass="spinner-wrapper page-spinner-wrapper behind" show/>
    }

    const slide4Title = getAside(contents, 'slide4Title')
    const slide5Title = getAside(contents, 'slide5Title')
    const slide6Title = getAside(contents, 'slide6Title')
    const slide7Title = getAside(contents, 'slide7Title')
    const slide9Image = getAside(contents, 'slide9Image')
    const slide9Title = getAside(contents, 'slide9Title')

    console.log(slide9Image, contents);

    return (
      <div className="about-page" style={customStyle}>
        <MetaHelmet
          metaTitle={contents.metaTitle}
          metaDescription={contents.metaDescription}
        />
        <div className={"content"}>
          {contents && Parser(contents.body)}

          <h4>{slide4Title && Parser(slide4Title)}</h4>
          <img src={loghiSostenitori} alt={slide4Title && Parser(slide4Title)}/>

          <p className={"greetings"}>{slide5Title && Parser(slide5Title)}</p>
          <img src={loghiSostenitori2} alt={slide4Title && Parser(slide4Title)}/>

          <p className={"greetings"}>{slide6Title && Parser(slide6Title)}</p>

          <p className={"greetings"}>{slide9Title && Parser(slide9Title)}</p>

          <p className={"greeting"}>
            <img
              src={getAttachmentUrl(contents.attachments && contents.attachments[slide9Image])}
              alt={slide9Title && Parser(slide9Title)} />
          </p>

          <p className={"greetings"}>{slide7Title && Parser(slide7Title)}</p>

          <p className={"credits"}>
            <a href={"http://www.lemaus.it/"} target={"_blank"}>
              <img src={logoLeMaus} alt="LeMaus - Agenzia di comunicazione"/>
            </a>
          </p>
          <p className={"credits"}>
            <a href={"http://www.ventie30.it/"} target={"_blank"}>
              <img src={logoVentie30} alt="Ventie30 - Agenzia di comunicazione digitale"/>
            </a>
          </p>
          <p className={"credits"}>
            <a href={"http://alessio-conti.it/"} target={"_blank"}>
              <img src={logoAlessioConti} alt="Alessio Conti - Social media manager & copy"/>
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(AboutPage)
