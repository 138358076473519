import {
  PERIODS_DATA,
  PERIODS_ERROR,
  PERIODS_FETCHING,
  PERIODS_SET_YEAR,
  PERIODS_SET_DECADE
} from '../actions/periods'

const PERIODS_INITIAL_STATE = {
  data: [],
  error: false,
  fetching: false,
  year: null,
  activeDecade: null
}

export const periods = (state = PERIODS_INITIAL_STATE, { type: actionType, ...payload }) => {
  switch(actionType){
    case PERIODS_FETCHING:
    case PERIODS_ERROR:
    case PERIODS_SET_YEAR:
    case PERIODS_SET_DECADE:
      return Object.assign({}, state, payload)
    case PERIODS_DATA:
      return Object.assign({}, state, {
        data: [...state.data, ...payload.data]
      })
    default:
      return state
  }
}
