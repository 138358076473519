import { createSelector } from 'reselect'

import { getContents } from './contents'

export const getCategories = state => state.categories

export const getCategoriesWithContents = createSelector(
  [getCategories, getContents],
  (categories, contents) => {
    if(!categories.data.length || !contents.data.length) return []

    return categories.data.map(category => {
      return {
        ...category,
        contents: [...contents.data].filter(content => content.categories.data.find(({ permalink }) => permalink === category.permalink))
      }
    })
  }
)
