import { combineReducers } from "redux"
import actionTypes from "../actions/actionTypes"

const value = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.fetchCurrentContentSuccess:
      return action.data
    case actionTypes.fetchCurrentContentFailure:
    case actionTypes.fetchCurrentContentRequest:
      return {}
    default:
      return state
  }
}

const error = (state = false, action) => {
  switch (action.type) {
    case actionTypes.fetchCurrentContentFailure:
      return true
    case actionTypes.fetchCurrentContentSuccess:
    case actionTypes.fetchCurrentContentRequest:
      return false
    default:
      return state
  }
}

const fetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.fetchCurrentContentRequest:
      return true
    case actionTypes.fetchCurrentContentSuccess:
    case actionTypes.fetchCurrentContentFailure:
      return false
    default:
      return state
  }
}

const currentContent = combineReducers({
  value,
  error,
  fetching
})

export default currentContent
