import { createSelector } from 'reselect'
import { getContents } from './contents'
import { getCategories } from './categories'
import 'core-js/fn/set'

export const getPeriods = state => state.periods

export const getPeriodsWithRelations = createSelector(
  [ getContents, getPeriods, getCategories ],
  (contents, periods, categories) => {
    if(!contents) return []

    return periods.data.map(period  => ({
      ...period,
      contents: contents.data.filter(({ type }) => type.id === period.id),
      locations: categories.data.reduce((accumulator, category) => {
        if(category.type.id !== period.id) {
          return accumulator
        }

        const master = contents.data.find(content => {
          return content.categories.data.some(contentCategory => contentCategory.id === category.id) && content.layoutId === 0
        })

        return [
          ...accumulator,
          {
            ...category,
            published: master && master.status === 1,
          }
        ]
      }, [])
    }))
  }
)

export const filterUnpublishedPeriods = (periods, contents, isPreview) => {
  if(isPreview) return periods

  return periods.map(period => {
    const periodPage = contents.data.find(content => content.permalink === period.permalink)
    const periodIsPublished = periodPage && periodPage.featured

    if(!periodIsPublished){
      return {
        ...period,
        locations: []
      }
    }

    return period
  })
}

const getPreviewFromState = state => !!state.preview

export const getFilteredPeriodsWithRelations = createSelector(
  [ getPeriods, getPeriodsWithRelations, getContents, getPreviewFromState ],
  (periods, periodsWithRelations, contents, isPreview) => {
    const publishedPeriods = filterUnpublishedPeriods(periodsWithRelations, contents, isPreview)

    if(!periods.year) return publishedPeriods

    return publishedPeriods.map(period => ({
      ...period,
      locations: period.contents.reduce((accumulator, { layoutId, tags, categories, type }) => {
        if(layoutId !== 0) return accumulator

        const contentMatchesYear = tags.data.some(({ label }) => label === periods.year)

        if(!contentMatchesYear) return accumulator

        return [
          ...accumulator,
          ...categories.data.map(category => ({ ...category, type}))
        ]
      }, [])
    }))
  }
)

export const flattenPeriodLocations = createSelector(
  [ getFilteredPeriodsWithRelations ],
  (periods) => {
    return periods.reduce((accumulator, period) => {
      return [
        ...accumulator,
        ...period.locations,
      ]
    }, [])
  }
)


export const flattenPeriodContents = createSelector(
  [ getPeriodsWithRelations ],
  (periods) => {
    return periods.reduce((accumulator, period) => ([
      ...accumulator,
      ...period.contents
    ]), [])
  }
)

export const getPeriodsYears = createSelector(
  [ flattenPeriodContents ],
  (contents) => {
    const years = new Set()

    contents.forEach(({ layoutId, tags }) => {
      if(layoutId === 0 && tags && tags.data && tags.data.length){
        tags.data.forEach(({ label }) => years.add(label))
      }
    }, [])

    return Array.from(years).sort((a, b) => a.localeCompare(b))
  }
)

export const getYears = (firstYear, lastYear) => {
  return Array
    .from({ length: lastYear - firstYear + 1 }, (_, i) => (firstYear + i).toString())
    .reduce((accumulator, year, index, currentArr) => {
      if(index % 10 === 0){
        const decade = currentArr.slice(index, index + 10)

        return {
          ...accumulator,
          [`${decade[0]}`]: decade
        }
      }

      return accumulator
    }, [])
}
