import React, {Component} from 'react'
import {extractData, mergeAsides, setAttachByPermalink, getSizeUrl} from '../../helpers/index'
import {connect} from 'react-redux'
import InnerPagesSlider from '../InnerPagesSlider'
import StaticPage from '../StaticPage.js'
import {getAttachmentFromAsides, getAside} from '../../helpers/contents'
import {getContents} from "../../selectors/contents";
import {fetchSingleContent, getLoadContentOptionsByPathname} from '../../actions/contents'
import {fetchSingleCategory, getLoadCategoriesOptionsByPathname} from '../../actions/categories'

import {MetaHelmet} from '../MetaHelmet/index'
import Spinner from '../Spinner'
import Home from '../Home'

const mapStateToProps = (state, ownProps) => {
  const contents = getContents(state)
  const baseContent = contents.data.find(({permalink: contentPermalink}) => contentPermalink === ownProps.match.params.pageId)
  const content = baseContent && setAttachByPermalink(mergeAsides(extractData(baseContent)))

  if (content) {
    return ({
      available: true,
      content,
      fetching: contents.fetching,
      slide1Image: content.slide1Image && content.attachments && content.attachments[content.slide1Image.body],
      slide2Image: content.slide2Image && content.attachments && content.attachments[content.slide2Image.body],
      slide3Image: content.slide3Image && content.attachments && content.attachments[content.slide3Image.body]
    })
  }
  return {
    available: false,
    fetching: contents.fetching
  }
}

class CategoryPage extends Component {
  _getBlend() {
    const blend = getAttachmentFromAsides(this.props.content, 'blend')

    if (blend && blend.hasOwnProperty('url')) return getSizeUrl(blend.url, 'md', 'lg', 'xl', 'lg')
  }

  _getLocationMap() {
    const locationMap = getAttachmentFromAsides(this.props.content, 'locationMap')

    if(locationMap && Object.prototype.hasOwnProperty.call(locationMap, 'url')) {
      return getSizeUrl(locationMap.url, 'md', 'lg', 'xl', 'lg')
    }
  }

  render() {
    const {
      content,
      fetching,
      available,
      slide1Image,
      slide2Image,
      slide3Image
    } = this.props

    if(!available){
      if(fetching){
        return <Spinner customClass="page-spinner-wrapper behind" show={fetching}/>
      } else {
        return <Home/>
      }
    }

    if (content.type && content.type.id === 4) {
      return <StaticPage {...content} />
    }

    const pageClass = 'page ' + content.permalink
    const slideshowContents = []
    slide1Image && slideshowContents.push(getSizeUrl(slide1Image.url, 'md', 'lg', 'xl', 'lg'))
    slide2Image && slideshowContents.push(getSizeUrl(slide2Image.url, 'md', 'lg', 'xl', 'lg'))
    slide3Image && slideshowContents.push(getSizeUrl(slide3Image.url, 'md', 'lg', 'xl', 'lg'))

    const slideshowContentTitles = []
    content.slide1Title && slideshowContentTitles.push(content.slide1Title.body)
    content.slide2Title && slideshowContentTitles.push(content.slide2Title.body)
    content.slide3Title && slideshowContentTitles.push(content.slide3Title.body)

    return (
      <div className={'cover-pages ' + pageClass}>
        <MetaHelmet metaTitle={content.metaTitle} metaDescription={content.metaDescription}/>
        <InnerPagesSlider
          id={content.id}
          title={content.title}
          subtitle={content.body}
          date={getAside(content, 'pageDate')}
          images={slideshowContents && slideshowContents}
          titles={slideshowContentTitles && slideshowContentTitles}
          blend={this._getBlend()}
          locationMap={this._getLocationMap()}
        />
      </div>
    )
  }
}

CategoryPage.serverFetch = pathname => dispatch => Promise.all([
  dispatch(fetchSingleContent(getLoadContentOptionsByPathname(pathname))),
  dispatch(fetchSingleCategory(getLoadCategoriesOptionsByPathname(pathname)))
])

export default connect(mapStateToProps, null)(CategoryPage)
