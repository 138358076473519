import { api } from "../../helpers/api";

export const contentsFetchServer = (skip = 0, options = {}) => {
  return api.get(`/contents/contents?params=${JSON.stringify({
    relations: ['asides', 'attachments', 'categories', 'tags', 'type'],
    skip,
    ...options
  })}`)
}

export const createContentRequestOptions = (options, additionalOptions = {}) => ({
  where: Object.assign(
    {},
    Object.entries(options).reduce((accumulator, [name, value]) => ({
      ...accumulator,
      [name]: value
    }), {}),
    additionalOptions
  )
})

export const makeServerSingleContentRequest = fetcher => {
  return properties => {
    return fetcher(0, createContentRequestOptions(properties))
  }
}

export const fetchSingleContentOnServer = makeServerSingleContentRequest(contentsFetchServer)
