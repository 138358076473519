import 'core-js/fn/object/entries'

import { apiUrl } from '../actions'

require('es6-promise').polyfill()
require('isomorphic-fetch')

export const fetchServerStatus = (url) =>
  fetch(
    url, 
    {
      method: 'HEAD'
    }
  )
    .then(res => {
      if (res.ok) return res
      else return new Error(res.statusMessage)
    })
    .catch(e => { throw e })

export const fetchData = (url) =>
  fetch(url)
    .then(res => {
      if (res.ok) {
        try {
          return res.json()
        } catch (e) {
        }
      }
      else return new Error(res.statusMessage)
    })
    .catch(e => { throw e })

export const postData = (url, body) =>
  fetch(
    url, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body
    }
  )
    .then(res => {
      if (res.ok) return 'Contenuto caricato con successo'
      else throw new Error(res.statusText)
    })
    .catch(e => { throw e })

const apiMetdods = {
  get: 'GET'
}

export const api = Object.entries(apiMetdods).reduce((accumulator, [prop, method]) => ({
  [prop]: (url) => {
    return fetch(apiUrl + url, { method })
      .then(res => {
        if(res.ok) return res.json()

        throw new Error(res.statusMessage)
      })
  }
}), {})
