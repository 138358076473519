import { makeSingleContentRequest, makeRequestForRemainingContents } from './index'
import { getCategories } from "../selectors/categories";

//TODO: update
import actionTypes from './actionTypes'

export const CATEGORIES_FETCHING = 'CATEGORIES_FETCHING'

export const categoriesFetching = fetching => ({
  type: CATEGORIES_FETCHING,
  fetching
})

export const CATEGORIES_ERROR = 'CATEGORIES_ERROR'

export const categoriesError = error => ({
  type: CATEGORIES_ERROR,
  error
})

export const CATEGORIES_DATA = 'CATEGORIES_DATA'

const categoriesData = data => ({
  type: CATEGORIES_DATA,
  data
})

//TODO: refactor, unire alle altre dello stesso tipo, es. fetchContents
export const categoriesFetch = (skip = 0, options = {}) => (dispatch, getState, api) => {
  dispatch(categoriesFetching(true))

  return api.get(`/contents/categories?params=${JSON.stringify({
    relations: ['type'],
    skip,
    ...options
  })}`)
    .then(({ data, nextSkip }) => {
      dispatch(categoriesData(data))

      if(nextSkip){
        return dispatch(categoriesFetch(nextSkip))
      }

      dispatch(categoriesFetching(false))
    })
    .catch(err => {
      dispatch(categoriesError(true))
      dispatch(categoriesFetching(false))
    })
}

export const fetchSingleCategory = makeSingleContentRequest(categoriesFetch, getCategories)
const fetchRemainingCategories = makeRequestForRemainingContents(categoriesFetch)

export const fetchCategories = (options) => (dispatch) => {
  if(!Object.keys(options).length) {
    return dispatch(categoriesFetch())
  }

  return dispatch(fetchSingleCategory(options))
    .then(() => dispatch(fetchRemainingCategories(options)))
}

export const getLoadCategoriesOptionsByPathname = pathname => {
  const [,category] = pathname.split('/')

  if(category && category.length) return { 'type.permalink': category }

  return {}
}

//TODO: update

export const setNextCategoryActive = {
  'type': actionTypes.setNextCategoryActive
}

export const setPrevCategoryActive = {
  'type': actionTypes.setPrevCategoryActive
}
