import React, { useCallback, useEffect, useState } from 'react'
import SideMenu from './../../components/SideMenu'
import MoreInfo from './../../components/MoreInfo'
import logoImg from '../../assets/images/livello9-logo.svg'
import iconMoreInfo from '../../assets/images/icon-plus.svg'
import { canUseDOM } from "../../helpers"

export default () => {
	const [showMenu, setShowMenu] = useState(!(canUseDOM && window.innerWidth <= 991))
	const [showMoreInfo, setShowMoreInfo] = useState(false)

	const toggleMenu = useCallback(() => {
		setShowMenu(prevState => !prevState)
	}, [])

	const toggleMoreInfo = useCallback(() => {
		setShowMoreInfo(prevState => !prevState)
	}, [])

	useEffect(() => {
		if (showMenu && canUseDOM && window.innerWidth <= 991) {
			document.documentElement.classList.add('lock-scroll')

			return () => {
				document.documentElement.classList.remove('lock-scroll')
			}
		}
	}, [showMenu])

	return (
		<header className={ showMenu ? 'main-header expanded' : 'main-header collapsed' }>
			<div className="background">
				<div className="level1" />
				<div className="level2" />
				<div className="level3" />
			</div>
			<a className="primary-button main-menu" onClick={toggleMenu}>
				<img itemProp="logo" src={logoImg} alt="Logo Aziendale"/>
			</a>
			<a className="primary-button more-info" onClick={toggleMoreInfo}>
				<img src={iconMoreInfo} alt="More info"/>
			</a>
			<SideMenu
				show={showMenu}
				toggleMenu = {() => {
					if (canUseDOM && window.innerWidth <= 991) {
						toggleMenu()
					}
				}}
			/>
			{ showMoreInfo ? <MoreInfo /> : '' }
		</header>
	)
}
