/**
 * Created by lucapel on 30/01/17.
 */

import React, { Component } from 'react'
import Slider from 'react-slick';
import Parser from "html-react-parser";
import { setHTML } from '../../helpers/index'
import { SectionVectorMap } from "../SectionVectorMap/index";
import { Glyphicon } from 'react-bootstrap'
import smoothscroll from 'smoothscroll-polyfill'

const withScrollDown = cb => {
  const slider = document.querySelector('.slider-wrapper')

  if(slider){
    const nextSection = slider.nextElementSibling

    if(nextSection){
      cb(nextSection)
    }
  }
}

const scrollDown = () => {
  withScrollDown(nextSection => {
    nextSection.scrollIntoView({
      behavior: 'smooth'
    })
  })
}

class InnerPagesSlider extends Component {
  state = { withScrollDown: false }

  static defaultProps = {
    blend: ""
  }

  componentDidMount(){
    smoothscroll.polyfill()

    withScrollDown(() => this.setState({ withScrollDown: true }))
  }

  _getBackgroundStyle(slide){
    const { blend } = this.props

    const style = {
      backgroundImage: `url("${slide}"), url("${blend}")`
    }

    if(!slide.length){
      return {
        ...style,
        backgroundColor: 'lightgray'
      }
    }

    if(blend){
      return style
    }

    return { backgroundImage: `url("${slide}")` }
  }

  _getSlideImages = () => {
    const {images} = this.props

    if (images.length) return images

    return ['']
  }

  render() {
    const { withScrollDown } = this.state
    const { date, title, subtitle, titles, id, locationMap, children } = this.props

    const settings = {
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 2000,
      fade: true,
      cssEase: 'linear'
    }

    return (
      <div className = { "slider-wrapper" }>
        {
          title &&
          <header>
            { date && <h2 dangerouslySetInnerHTML={setHTML(date)} /> }
            <h1 dangerouslySetInnerHTML={setHTML(title)} />
            <div className={'subtitle'}>
              {
                subtitle && Parser(subtitle)
              }
							{children}
              {
                withScrollDown
                  && <Glyphicon glyph={'menu-down'} className={'arrow'} onClick={scrollDown}/>
              }
            </div>
          </header>
        }
        <Slider {...settings} className = { "inner-pages-slider" }>
          {
            this._getSlideImages().map( (slide, index) => (
              <div key={slide}>

                <div className = { "slide-image" } style={this._getBackgroundStyle(slide)} />
                <div className = { "corner-info" }>
                  { titles && titles[index] && <h4 dangerouslySetInnerHTML={setHTML(titles[index])} /> }
                  <SectionVectorMap map={locationMap} id = { id } />
                </div>
              </div>
            ))
          }
        </Slider>
      </div>
    )
  }
}

export default InnerPagesSlider
