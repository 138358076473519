import React from 'react'
import Helmet from 'react-helmet'

export const MetaHelmet = ({ metaTitle, metaDescription }) => {
  return (
    <Helmet
      title={metaTitle || "Livello9"}
      meta={[
        { name: "title", content: metaTitle || "Livello9" },
        { name: "description", content: metaDescription || "Livello9 - Museo dei luoghi del '900" }
      ]}
    />
  )
}

MetaHelmet.defaultProps = {
  metaTitle: "Livello9",
  metaDescription: "Livello9 - Museo dei luoghi del '900"
}
