import React, { Component } from 'react'
import {Col, Grid, Row} from "react-bootstrap"
import {getAside, getAttachmentUrlFromAsides, getTitleAndSubtitle} from "../../../helpers/contents"

import { MediaContent } from './Content'

export class Media extends Component{
  static defaultProps = {
    type: 'pdf'
  }

  _getMediaContentData(){
    const { type, ...section } = this.props

    switch (type) {
      case 'audio':
        if(section.hasOwnProperty('audioMp3')){
          return getAttachmentUrlFromAsides(section, 'audioMp3')
        } else if(section.hasOwnProperty('audioOgg')){
          return getAttachmentUrlFromAsides(section, 'audioOgg')
        } else {
          return null
        }
      default:
        return null
    }
  }

  render(){
    const { type, ...section } = this.props

    if(!type) return null

    const { title, subtitle } = getTitleAndSubtitle(getAside(section, `${type}Text`))

    return(
      <section className={'page-section section-media'}>
        <Grid className={'no-gutter'} fluid>
          <Row>
            <Col md={7} mdOffset={4}>
              <PdfWrapper href={getAttachmentUrlFromAsides(section, 'pdf')}>
                <div className={'media-type'}>
                  {
                    type === 'audio'
                      ? <MediaContent id={section.id} layout={type} data={this._getMediaContentData()}/>
                      : type
                  }
                </div>

                <div className={`media-text ${type}` }>
                  <div className={'title'} dangerouslySetInnerHTML={{ __html: title}}/>
                  { subtitle && <div className={'subtitle'} dangerouslySetInnerHTML={{ __html: subtitle}}/> }
                </div>
              </PdfWrapper>
              {
                section && section.body &&
                <div className={'content'} dangerouslySetInnerHTML={{ __html: section.body }}/>
              }
            </Col>
          </Row>
        </Grid>
      </section>
    )
  }
}

const PdfWrapper = ({ href, children }) => {
  if(href){
    return (
      <a href={href} target={'_blank'} className={'media-wrapper'}>
        {children}
      </a>
    )
  }

  return(
    <div className={'media-wrapper'}>
      {children}
    </div>
  )
}
