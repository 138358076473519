import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class MoreInfo extends Component {

  render() {
    return (
      <nav className="more-info">
        <ul>
          <li>
            <Link to={'/museo'}>MUSEO</Link>
          </li>
          <li><a href = { "https://www.istoreco.re.it/passeggiateguidate/" } target = { "_blank" }>VISITE</a></li>
          <li><a href = { "https://www.istoreco.re.it/didattica/" } target = { "_blank" }>DIDATTICA</a></li>
          <li><a href="mailto:comunicazione@istoreco.re.it">CONTATTI</a></li>
        </ul>
      </nav>
    )
  }
}

export default MoreInfo
