import React, { Component } from "react"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps"

class Map extends Component {
  render = () => {
    const { markers, center, coordinates } = this.props

    const mapStyles = require("./mapStyles");

    return(
      <GoogleMap
        defaultZoom = { 13 }
        center = { coordinates }
        defaultOptions={{
          fullscreenControl: false,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          styles: mapStyles
        }}

        onClick = { () => window.open("https://maps.google.com/maps?q="+ coordinates.lat +","+ coordinates.lng, "_blank") }
      >
        <Marker
          position = { coordinates }
        />
      </GoogleMap>
    )
  }
}

export default withScriptjs(withGoogleMap(Map))
