import { createBrowserHistory, createMemoryHistory } from 'history'
import { scroller } from 'react-scroll'

export const canUseDOM = !!(
  (typeof window !== 'undefined' &&
    window.document && window.document.createElement)
)

// const getHistory = canUseDOM ? require('history/createBrowserHistory') : require('history/createMemoryHistory')

function getHistory() {
  if (canUseDOM) {
    return createBrowserHistory();
  }

  return createMemoryHistory();
}

export const history = getHistory();

export const navigateToPage = (e, href) => {
  e.preventDefault()
  history.push(href)
}

export const scrollToElement = (e, element) => {
  e.preventDefault()
  scroller.scrollTo(element, {
    duration: 800,
    delay: 0,
    smooth: true
  })
}

export const setHTML = (html) => ({ __html: html })

export const convertToSlug = (text) => {
  return text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
}

export const getConfig = (configFileName = 'config') => {
  const env = process.env.REACT_APP_ENV || process.env.NODE_ENV
  if (env === 'production') return require(`../${configFileName}.production.json`)
  else return require(`../${configFileName}.json`)
};

export const getLanguages = (asides) => {
  const allLanguages = asides ? asides.data.map(item => item.lang) : []

  return new Set(allLanguages)
}

export const getFeaturedImage = (attachments) => {
  if (attachments) {
    const attach = attachments.data.find(({ status, type, featured }, i) =>
      (i === 0) && (type === 'img') && (status === 1) && (featured === 1))
    return attach ? attach.url : ''
  }
  return null
}

// questi potrebbero servirti

export const extractData = data => {
  return Object.entries(data).reduce((accumulator, [key, value]) => ({
    ...accumulator,
    [key]: value && value.data ? value.data : value
  }), {})
}

export const mergeAsides = data =>
  Object.entries(data).reduce((accumulator, [key, value]) => {
    if (key === 'asides' && value) {
      return {
        ...accumulator,
        ...value.reduce((accumulator, aside) => ({
          ...accumulator,
          [aside.handler]: aside
        }), {})
      }
    }
    return {
      ...accumulator,
      [key]: value
    }
  }, {})

export const setAttachByPermalink = data =>
  data.attachments ?
    {
      ...data,
      attachments: data.attachments.reduce((accumulator, current) => ({
        ...accumulator,
        [current.permalink]: current
      }), {})
    } :
    data


export const getSizeUrl = (url, smallScreens = null, midScreens = null, largeScreens = null, firstLoad = null) => {
  if (!canUseDOM) return firstLoad ? url[firstLoad] : url.md

  if ( smallScreens && midScreens && largeScreens ) { //if the desired values are set...

    if (window.innerWidth < 500) {
      return url[smallScreens]
    }
    if (window.innerWidth < 1280) {
      return url[midScreens]
    }
    return url[largeScreens]

  } else {

    if (window.innerWidth <= 500) {
      return url.sm
    }
    if (window.innerWidth <= 960) {
      return url.md
    }
    if (window.innerWidth <= 1280) {
      return url.lg
    }
    return url.xl
  }
}

/*
  export const getSizeUrl = url => {
    if (window.innerWidth < 576) {
      return url.th
    }
    if (window.innerWidth < 768) {
      return url.sm
    }
    if (window.innerWidth < 992) {
      return url.md
    }
    if (window.innerWidth < 1200) {
      return url.lg
    }
    return url.xl
  }
*/

export const parseQueryString = (queryString) => {
  if(!queryString) return {}

  return queryString
    .slice(queryString.indexOf('?') + 1)
    .split('&')
    .reduce((accumulator, param) => {
      const [name, value] = param.split('=')

      return {
        ...accumulator,
        [name]: value
      }
    }, {})
}
