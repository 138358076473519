import { CONTENTS_ADD, CONTENTS_FETCHING, CONTENTS_ERROR } from '../actions/contents'

export const CONTENTS_INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false
}

export const contents = (state = CONTENTS_INITIAL_STATE, action) => {
  switch(action.type){
    case CONTENTS_ADD:
      return Object.assign({}, state, {
        data: [...state.data, ...action.data]
      })
    case CONTENTS_ERROR:
      return Object.assign({}, state, {
        error: action.error
      })
    case CONTENTS_FETCHING:
      return Object.assign({}, state, {
        fetching: action.fetching
      })
    default:
      return state
  }
}
