import React, { Component } from "react"
import { Grid, Row, Col } from "react-bootstrap"

import {getAside, getAttachmentUrlFromAsides, getMetaFromAsides} from "../../../helpers/contents";
import Parser from "html-react-parser";
import { Image } from "../../Image/index";

class SectionFullwidthImage extends Component {

  render() {

    const {
      imageText,
      ...section
    } = this.props

    const image = getAttachmentUrlFromAsides(section, 'image1')
    const meta = getMetaFromAsides(section, 'image1')

    return (
      <section className="page-section section-fullwidth-image">
        <Grid className={ "no-gutter" } fluid>
          <Row>
            <Col sm={12}>
              <div className="images">
                { image && <Image color={getAside(section, 'imagesStyle')} src={ image } alt={ meta.alt } /> }
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={3} smOffset={8}>
              <div className = { "image-description" }>
                { imageText && Parser(imageText) }
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={7} smOffset={4}>
              <div className="content">
                { section && section.body && Parser(section.body) }
              </div>
            </Col>
          </Row>
        </Grid>
      </section>
    )
  }
}

export default SectionFullwidthImage
