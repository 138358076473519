import rootReducer from "./reducers";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "remote-redux-devtools";
import { createLogger } from 'redux-logger'

import { api } from "./helpers/api";

const logger = createLogger()

const middleware = [
  thunk.withExtraArgument(api)
]

const enhancers = (() => {
  if(process.env.NODE_ENV === 'development'){
    const composeEnhancers = composeWithDevTools({ hostname: 'localhost', port: 8000 })

    return composeEnhancers(applyMiddleware(...middleware, logger))
  }

  return applyMiddleware(...middleware)
})()

const preloadedState = window.__PRELOADED_STATE__

delete window.__PRELOADED_STATE__

export default createStore(rootReducer, preloadedState, enhancers)
