import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga'
import CookieConsent from 'react-cookie-consent'

import Routes from '../../routes'
import Header from './../../components/Header'
import Footer from './../../components/Footer'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Spinner from './../../components/Spinner'
import { canUseDOM } from "../../helpers"
import { getAppStatus } from '../../selectors/app'
import { appLoadingStatus } from '../../actions/app'
import { fetchSingleContent, fetchRemainingContents, getLoadContentOptionsByPathname } from "../../actions/contents"
import { getContents } from '../../selectors/contents'
import {
  getCategoriesWithRelations,
  getSelectedLang
} from './../../selectors'

import {
  setLanguage
} from './../../actions'

import { MetaHelmet } from '../../components/MetaHelmet'

const handleScroll = () => {
  const app = document.querySelector('.app')
  const className = 'secondary-section'

  const scrollTop = document.documentElement.scrollTop
    || document.body.parentNode.scrollTop
    || document.body.scrollTop
  if (scrollTop >= (window.innerHeight * .6)) {
    if(app) app.classList.add(className)
  } else {
    if(app && !app.classList.contains('static-page')) app.classList.remove(className)
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: getAppStatus(state).loading,
    categories: getCategoriesWithRelations(state),
    selectedLanguage: getSelectedLang(state),
    currentLang: getSelectedLang(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadContents: (options = {}) => {
    return dispatch(fetchSingleContent(options, getContents))
      .then(() => dispatch(fetchRemainingContents(options)))
  },
  selectLanguage: (lang) => {
    dispatch(setLanguage(lang))
  },
  setAppLoadingStatus: loading => dispatch(appLoadingStatus(loading))
})

class App extends Component {
  componentDidUpdate(prevProps){
    if (canUseDOM) window.scrollTo(0, 0)
  }

  //TODO: da spostare in componentDidUpdate
  componentWillReceiveProps(nextProps) {
    let curLang = ''
    if (nextProps.currentLang === 'it' || nextProps.currentLang === undefined) { curLang = 'it' }
    else { curLang = nextProps.currentLang }
    if (nextProps.selectedLanguage !== curLang) {
      this.props.selectLanguage(curLang)
    }
  }

  componentDidMount() {
    const { loadContents, location, history } = this.props

    loadContents(getLoadContentOptionsByPathname(location.pathname))

    ReactGA.initialize('UA-87928124-2', { debug: false })

    if (canUseDOM) {
      window.addEventListener('scroll', handleScroll)

      ReactGA.set({ anonymizeIp: true })
      ReactGA.set({ page: window.location.pathname })
      ReactGA.pageview( window.location.pathname )
    }

    history.listen(location => {
      const googleUrl = location.pathname + location.search
      ReactGA.set({ anonymizeIp: true })
      ReactGA.set({ page: googleUrl })
      ReactGA.pageview(googleUrl)
    })
  }

  render() {
    const { isLoading, location } = this.props
    const { pathname } = location

    const routeKey = (pathname.split('/')[2] !== '') ? pathname.split('/')[1] + "-" + pathname.split('/')[2] : pathname.split('/')[1]
    const parentCategoryClass = pathname.split('/')[1] || 'homepage'

    return (
      <div className={'app ' + parentCategoryClass}>
        <Spinner customClass="spinner-wrapper page-spinner-wrapper" show={isLoading}/>
        <Helmet
          htmlAttributes={{ lang: 'it', amp: undefined }} // amp takes no value
          titleAttributes={{ itemprop: 'name', lang: 'it' }}
        />
        <MetaHelmet/>
        <Header />
        <TransitionGroup className="transition-group">
          <CSSTransition key={routeKey} timeout={{ enter: 810, exit: 1010 }} classNames="page" mountOnEnter={true} unmountOnExit={true}>
            <div>
              <Switch location={this.props.location}>
                <Routes location={this.props.location} />
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
        <Footer />
        <CookieConsent
          buttonText={'Ho capito'}
        >
          <span>
            Utilizziamo i cookie per assicurarci che tu abbia la migliore esperienza possibile sul nostro sito.
            Continuando la navigazione sul sito accetti la nostra cookie policy.
            &nbsp;
            <Link to = { "/privacy" } style={{ color: "#fff" }}>Maggiori informazioni</Link>
          </span>
        </CookieConsent>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
