import { makeSingleContentRequest, makeRequestForRemainingContents } from './index'
import { getContents } from "../selectors/contents";

export const CONTENTS_ADD = 'CONTENTS_ADD'

export const contentsAdd = data => ({
  type: CONTENTS_ADD,
  data
})

export const CONTENTS_FETCHING = 'CONTENTS_FETCHING'

export const contentsFetching = fetching => ({
  type: CONTENTS_FETCHING,
  fetching
})

export const CONTENTS_ERROR = 'CONTENTS_ERROR'

export const contentsError = error => ({
  type: CONTENTS_ERROR,
  error
})

export const contentsFetch = (skip = 0, options = {}) => (dispatch, getState, api) => {
  dispatch(contentsFetching(true))

  return api.get(`/contents/contents?params=${JSON.stringify({
    relations: ['asides', 'attachments', 'categories', 'tags', 'type'],
    skip,
    ...options
  })}`)
    .then(({data, nextSkip}) => {
      dispatch(contentsAdd(data))

      if (nextSkip) {
        return dispatch(contentsFetch(nextSkip, options))
      }

      dispatch(contentsFetching(false))
    })
    .catch(() => {
      dispatch(contentsError(true))
      dispatch(contentsFetching(false))
    })
}

export const fetchSingleContent = makeSingleContentRequest(contentsFetch, getContents)
export const fetchRemainingContents = makeRequestForRemainingContents(contentsFetch)

export const getLoadContentOptionsByPathname = pathname => {
  const [,category, page] = pathname.split('/')

  if(page && page.length) return { 'categories.permalink': page }
  else if(category && category.length) return { permalink: category }
  else return { id: 1 }
}
