import React, { Component } from "react"
import Parser from "html-react-parser"
import { Grid, Row, Col } from "react-bootstrap"
import Slide from "react-reveal/Slide"

import { getAttachmentUrlFromAsides, getAside } from "../../../helpers/contents";
import { Image } from "../../Image/index";

export class LayoutA extends Component {
  render() {

    const {
      ...section
    } = this.props

    const image1 = getAttachmentUrlFromAsides(section, 'image1')
    const image1Text = getAside(section, 'image1Text')

    const image2 = getAttachmentUrlFromAsides(section, 'image2')
    const image2Text = getAside(section, 'image2Text')

    return (
      <section className="page-section section-side-by-side-images">
        <Grid className={ "no-gutter" } fluid>
          <Row>
            <Col md={7} mdOffset={4}>
              <div>
                <Row>
                  <Col sm={11}>
                    <h2 className="teaser">
                      { section && section.body && Parser(section.body) }
                    </h2>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col className="img-left" sm={6}>
                  <div className={ "image-wrap" }>
                    <Image color={getAside(section, 'image1Style')} src={ image1 } alt="" />
                  </div>
                  <div className = { "image-description" }>
                    { image1Text && Parser(image1Text) }
                  </div>
                </Col>
                <Slide left>
                  <Col className="img-right" sm={6}>
                    <div className={ "image-wrap" }>
                      <Image color={getAside(section, 'image2Style')} src={ image2 } alt="" />
                    </div>
                    <div className = { "image-description" }>
                      { image2Text && Parser(image2Text) }
                    </div>
                  </Col>
                </Slide>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col sm={6} smOffset={4}>
              <Row>
                <Col sm={12}>
                  <div className="content" dangerouslySetInnerHTML={{ __html: getAside(section, 'sectionText') }}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </section>
    )
  }
}
