export default {
  "fetchCategoriesRequest": "FETCH_CATEGORIES_REQUEST",
  "fetchCategoriesFailure": "FETCH_CATEGORIES_FAILURE",
  "fetchCategoriesSuccess": "FETCH_CATEGORIES_SUCCESS",
  "fetchCurrentContentRequest": "FETCH_CURRENT_CONTENT_REQUEST",
  "fetchCurrentContentFailure": "FETCH_CURRENT_CONTENT_FAILURE",
  "fetchCurrentContentSuccess": "FETCH_CURRENT_CONTENT_SUCCESS",
  "setActiveCategory": "SET_ACTIVE_CATEGORY",
  "setPrevCategoryActive": "SET_PREVIOUS_CATEGORY_ACTIVE",
  "setNextCategoryActive": "SET_NEXT_CATEGORY_ACTIVE",
  "filterContents": "FILTER_CONTENTS"
}
