import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Audio } from './Audio'

export class MediaContent extends Component{
  static propTypes = {
    layout: PropTypes.oneOf(['pdf', 'audio']),
    id: PropTypes.number.isRequired
  }

  static defaultProps = {
    layout: 'pdf'
  }

  render(){
    const { layout, data, id } = this.props
    switch(layout){
      case 'audio':
        return <Audio id={id} audio={data}/>
      default:
        return null
    }
  }
}
