import React from 'react'
import { Provider } from 'react-redux'
import { render, hydrate } from 'react-dom'
import App from './containers/App'
import { history } from './helpers'
import { Router } from 'react-router-dom'
import './shared/scss/base.scss'
import store from "./store"
import '@babel/polyfill'

const Page = () => (
  <Provider store={store}>
    <Router
      history={history}
    >
      <App />
    </Router>
  </Provider>
)

const root = document.getElementById('root')

if(root.hasChildNodes()){
  hydrate(<Page/>, root)
} else {
  render(<Page/>, root)
}
