import React, {Component} from 'react'
//import { connect } from 'react-redux'
import {Grid, Row, Col} from 'react-bootstrap';
import BottomBar from "../../components/BottomBar"

import facebook from '../../assets/images/facebook-logo.svg'
import youtube from '../../assets/images/youtube-logo.svg'
import instagram from '../../assets/images/instagram-logo.svg'
import istoreco from '../../assets/images/istoreco-logo.svg'

class Footer extends Component {

  render() {
    return (
      <footer id="footer" itemScope itemType="http://schema.org/Organization">
        <Grid fluid>
          <Row>
            <Col xs={5} sm={8} md={9}>
              <BottomBar />
            </Col>
            <Col xs={7} sm={4} md={3} className={"social"}>
              <a href={"https://www.facebook.com/Istoreco-101838536518300"} target={"_blank"}>
                <img className="img-responsive" src={facebook} alt="Facebook"/>
              </a>
              <a href={"https://www.youtube.com/channel/UCdrgif8EQTJ9sZEs9GlSYEQ"} target={"_blank"}>
                <img className="img-responsive" src={youtube} alt="Youtube"/>
              </a>
              <a href={"https://www.instagram.com/comunicazione_istoreco/"} target={"_blank"}>
                <img className="img-responsive" src={instagram} alt="Instagram"/>
              </a>
              <a href={"https://www.istoreco.re.it/"} target={"_blank"}>
                <img className="img-responsive" src={istoreco} alt="Istoreco"/>
              </a>
            </Col>
          </Row>
        </Grid>
      </footer>
    )
  }
}

export default Footer
