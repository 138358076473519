import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Home from './components/Home'
import Content from './components/Content'
import AboutPage from './components/AboutPage'
import CategoryPage from './components/CatgoryPage'
import Contacts from './components/Contacts'
import Privacy from './components/Privacy'

export const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/:categoryId/:pageId',
    component: Content,
    exact: true
  },
  {
    path: '/museo',
    component: AboutPage,
    exact: true
  },
  {
    path: '/contatti',
    component: Contacts,
    exact: true
  },
  {
    path: '/privacy',
    component: Privacy,
    exact: true
  },
  {
    path: '/:pageId',
    component: CategoryPage,
    exact: true
  }
]

const Routes = (props) => {
  return(
    <Switch>
      {
        routes.map(route => {
          return <Route location={props.location} {...route} key={route.path}/>
        })
      }
    </Switch>
  )
}

export default withRouter(Routes)
