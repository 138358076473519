import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchSingleContent, getLoadContentOptionsByPathname } from '../../actions/contents'
import parse from 'html-react-parser'

import InnerPagesSlider from '../InnerPagesSlider'
import { MetaHelmet } from '../MetaHelmet'

import {
  getHomeContents,
  getSlideshowContents
} from '../../selectors'

const mapStateToProps = state => ({
  contents: getHomeContents(state),
  slideshowContents: getSlideshowContents(state)
})

class Home extends Component {
  render() {
    const { slideshowContents, contents } = this.props

    if(!Object.keys(contents).length) return null

    return (
      <div className="homepage">
        <MetaHelmet metaTitle={contents.metaTitle} metaDescription={contents.metaDescription}/>
        <InnerPagesSlider
          id={contents.id}
          images={slideshowContents.reverse()}
          blend={null}
        />
      </div>
    )
  }
}

Home.serverFetch = pathname => dispatch => {
  return dispatch(fetchSingleContent(getLoadContentOptionsByPathname(pathname)))
}

export default connect(mapStateToProps)(Home)
