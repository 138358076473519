import { combineReducers } from 'redux'
import currentContent from "./currentContent"
import actionTypes from "../actions/actionTypes"
import { periods } from './periods'
import { contents } from './contents'
import { app } from './app'
import { categories } from './categories'

const serverStatus = (state = true, action) => {
  switch (action.type) {
    case 'UPDATE_SERVER_STATUS':
      return action.serverStatus
    default:
      return state
  }
}

const isLoading = (state = true, action) => {
  switch (action.type) {
    case 'SET_LOADING_STATE':
      return action.isLoading
    default:
      return state
  }
}

const isSending = (state = false, action) => {
  switch (action.type) {
    case 'SET_SENDING_STATE':
      return action.isSending
    default:
      return state
  }
}

const selectedLang = (state = 'it', action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      if (action.lang !== undefined) return action.lang
      else return state
    default:
      return state
  }
}

const CONTENTS_INITIAL_STATE = {
  error: null,
  data: []
}

const contentsOld = (state = CONTENTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case 'RECEIVE_CONTENTS': {
      return {
        error: action.error,
        data: [...state.data, ...action.data]
      }
    }
    case 'RESET_CONTENTS_DATA': {
      return {
        error: null,
        data: null
      }
    }
    default: {
      return state
    }
  }
}

const response = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_RESPONSE':
      return ({error: action.error, success: action.success})
    case 'RESET_RESPONSE_STATE':
      return ({error: null, success: null})
    default:
      return state
  }
}

const filteredContents = (state = [], action) => {
  switch (action.type) {
    case actionTypes.filterContents:
      return action.list
    default:
      return state
  }
}

const rootReducer = combineReducers({
  app,
  contents: contentsOld,
  contentsNext: contents,
  filteredContents,
  selectedLang,
  isLoading,
  isSending,
  response,
  serverStatus,
  categories,
  currentContent,
  periods
})

export default rootReducer
