//-+ Diritti
import Map27 from "../../assets/images/maps/meno-piu-diritti.svg"
import Map16 from "../../assets/images/maps/asili-del-popolo.svg"
import Map2 from "../../assets/images/maps/calzificio-bloch-calzificio-reggiano.svg"
import Map10 from "../../assets/images/maps/canonica-san-pellegrino.svg"
import Map5 from "../../assets/images/maps/carcere-san-tommaso.svg"
import Map15 from "../../assets/images/maps/case-della-costituzione.svg"
import Map13 from "../../assets/images/maps/caserma-zucchi-unimore-dossetti.svg"
import Map9 from "../../assets/images/maps/casino-nobili-villa-cavazzoli.svg"
import Map8 from "../../assets/images/maps/distretto-militare-di-reggio-emilia.svg"
import Map14 from "../../assets/images/maps/municipio-reggio-emilia.svg"
import Map3 from "../../assets/images/maps/officine-meccaniche-italiane-reggiane.svg"
import Map4 from "../../assets/images/maps/piazza-della-vittoria.svg"
import Map7 from "../../assets/images/maps/prefettura-di-reggio-emilia.svg"
import Map12 from "../../assets/images/maps/teatro-municipale.svg"
import casaManfredi from "../../assets/images/maps/CASA MANFREDI-14.svg"

//I-Guerra-Mondiale
import guerraMondialeCover from '../../assets/images/maps/001 Cover I GUERRA M.svg'
import mutilato from '../../assets/images/maps/016 I GUERRA M_Casa del mutilato.svg'
import borgo from '../../assets/images/maps/012 I GUERRA M_Borgo emilio.svg'
import casermaSanMarco from '../../assets/images/maps/004 I GUERRA M_Caserma san Marco.svg'
import cimiteroMonumentale from '../../assets/images/maps/015 I GUERRA M_Cimitero monum.svg'
import comitatoFemminile from '../../assets/images/maps/011 I GUERRA M_Comitato ass femminile.svg'
import industrieAusiliarie from '../../assets/images/maps/009 I GUERRA M_Industrie ausiliarie.svg'
import liceoClassico from '../../assets/images/maps/008 I GUERRA M_Scuole.svg'
import ospedaliMilitari from '../../assets/images/maps/006 I GUERRA M_Ospedali militari.svg'
import piazzaBattisti from '../../assets/images/maps/003 I GUERRA M_Piazza Cesare Battisti.svg'
import piazzaVittoria from '../../assets/images/maps/002 I GUERRA M_Piazza della Vittoria.svg'
import sanLazzaro from '../../assets/images/maps/007 I GUERRA M_Centro psichiatrico.svg'
import stazioneFerroviaria from '../../assets/images/maps/005 I GUERRA M_Stazione Ferroviaria.svg'
import torreBordello from '../../assets/images/maps/014 I GUERRA M_Torre del bordello.svg'
import viaFarini from '../../assets/images/maps/010 I GUERRA M_Camera del Lavoro.svg'
import giornaleReggio from '../../assets/images/maps/013 I GUERRA M_Giornale di reggio.svg'

//Amicizia reggio-africa
import salaAmiciziaReggioAfrica from '../../assets/images/maps/Risorsa 001.svg'
import salaDelTricolore from '../../assets/images/maps/Risorsa 002.svg'
import teatroMunicipale from '../../assets/images/maps/Risorsa 003.svg'
import casaDelMutilato from '../../assets/images/maps/Risorsa 004.svg'
import magazzinoComunale from '../../assets/images/maps/Risorsa 005.svg'
import arcispedaleSantaMariaNuova from '../../assets/images/maps/Risorsa 006.svg'
import scuolaGavasseto from '../../assets/images/maps/Risorsa 007.svg'
import scuolaParrochialeDiMancasale from '../../assets/images/maps/Risorsa 008.svg'
import riunioneVertici from '../../assets/images/maps/Risorsa 09.svg'
import viaGandhi from '../../assets/images/maps/Risorsa 010.svg'
import viaAlbert from '../../assets/images/maps/Risorsa 012.svg'
import viaPatrice from '../../assets/images/maps/Risorsa 013.svg'
import viaMartiri from '../../assets/images/maps/Risorsa 014.svg'
import parcoDellaPace from '../../assets/images/maps/Risorsa 015.svg'
import parcoNelsonMandela from '../../assets/images/maps/Risorsa 016.svg'
import parcoSamora from '../../assets/images/maps/Risorsa 017.svg'
import amiciziaReggioAfricaCover from '../../assets/images/maps/complessiva.svg'

//Reggiana Calcio
import reggianaCalcioCover from '../../assets/images/maps/00 REGGIANA CALCIO - tutti i luoghi.svg'
import mirabello from '../../assets/images/maps/01 REGGIANA CALCIO Mirabello.svg'
import badia from '../../assets/images/maps/02 REGGIANA CALCIO Badia.svg'
import piazzaPrampolini from '../../assets/images/maps/03 REGGIANA CALCIO P Prampolini.svg'
import viaGuasco from '../../assets/images/maps/04 REGGIANA CALCIO V. Guasco.svg'
import villaGranata from '../../assets/images/maps/05 REGGIANA CALCIO Villa Granata.svg'
import cimiteroMonumentaleReggianaCalcio from '../../assets/images/maps/06 REGGIANA CALCIO Cim. Monumentale.svg'
import bibliotecaPanizzi from '../../assets/images/maps/07 REGGIANA CALCIO Panizzi.svg'
import rostaNuova from '../../assets/images/maps/08 REGGIANA CALCIO Rosta Nuova.svg'
import monumentoHeysel from '../../assets/images/maps/09 REGGIANA CALCIO Heysel.svg'
import casaDelFascio from '../../assets/images/maps/10 REGGIANA CALCIO Casa del Fascio Ospizio.svg'
import piazzaVittoriaReggianaCalcio from '../../assets/images/maps/11 REGGIANA CALCIO Piazza Vittoria.svg'
import stazioneFerroviariaReggianaCalcio from '../../assets/images/maps/12 REGGIANA CALCIO Stazione Ferroviaria.svg'
import stadioCittaDelTricolore from '../../assets/images/maps/13 REGGIANA CALCIO Stadio Città del Tricolore.svg'

export const maps = {
  261: Map16,
  49: Map2,
  155: Map10,
  108: Map5,
  250: Map15,
  177: Map13,
  144: Map9,
  130: Map8,
  239: Map14,
  80: Map3,
  96: Map4,
  119: Map7,
  166: Map12,
  27: Map27,
  440: casaManfredi,
  192: mutilato,
  63: borgo,
  280: casermaSanMarco,
  218: cimiteroMonumentale,
  232: comitatoFemminile,
  315: industrieAusiliarie,
  327: liceoClassico,
  289: ospedaliMilitari,
  207: piazzaBattisti,
  298: piazzaVittoria,
  272: sanLazzaro,
  336: stazioneFerroviaria,
  225: torreBordello,
  306: viaFarini,
  199: giornaleReggio,
  64: guerraMondialeCover,
  357: casaDelMutilato,
  358: magazzinoComunale,
  359: arcispedaleSantaMariaNuova,
  366: parcoDellaPace,
  367: parcoNelsonMandela,
  368: parcoSamora,
  362: riunioneVertici,
  353: salaDelTricolore,
  352: salaAmiciziaReggioAfrica,
  360: scuolaGavasseto,
  361: scuolaParrochialeDiMancasale,
  356: teatroMunicipale,
  364: viaAlbert,
  363: viaGandhi,
  365: viaMartiri,
  369: viaPatrice,
  350: amiciziaReggioAfricaCover,
  477: reggianaCalcioCover,
  478: mirabello,
  486: badia,
  479: viaGuasco,
  487: villaGranata,
  488: bibliotecaPanizzi,
  489: rostaNuova,
  490: casaDelFascio,
  492: piazzaVittoriaReggianaCalcio,
  493: stazioneFerroviariaReggianaCalcio,
  494: stadioCittaDelTricolore,
  504: piazzaPrampolini,
  505: cimiteroMonumentaleReggianaCalcio,
  506: monumentoHeysel
}
