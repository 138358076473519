import React, { Component } from "react"
import { Grid, Row, Col, Button, Glyphicon } from "react-bootstrap"
import ReactPlayer from 'react-player'

import { getAside } from '../../../helpers/contents'
import Parser from "html-react-parser";

const getVideoCol = quality => {
  switch(quality) {
    case "0":
      return 7
    case "1":
    default:
      return 12
  }
}

export class LayoutD extends Component {
  constructor(props){
    super(props)

    this.state = {
      isVideoPlaying: false
    }

    this._toggleVideo = this._toggleVideo.bind(this)
  }

  _toggleVideo(){
    this.setState({ isVideoPlaying: !this.state.isVideoPlaying })
  }

  render() {
    const { isVideoPlaying } = this.state
    const { body } = this.props

    const videoText = getAside(this.props, 'videoText')
    const video = getAside(this.props, 'condensedVideoUrl')

    return (
      <section className="page-section section-video-with-pdf">
        <Grid className={ "no-gutter" } fluid>
          <Row>
            <Col md={7} mdOffset={4}>
              <Row>
                <Col sm={getVideoCol(getAside(this.props, 'videoQuality'))}>
                  <div>
                    <div className="player-wrapper">
                      <ReactPlayer
                        url= { video }
                        className={ "react-player" }
                        width={ "100%" }
                        height={ "100%" }
                        playing={ isVideoPlaying }
                        onStart={this._toggleVideo}
                      />
                      <Button className = { "video-toggle bottom-left" } onClick={ this._toggleVideo }>
                        <Glyphicon glyph={ isVideoPlaying ? "pause" : "play" } />
                      </Button>
                    </div>

                    <div className={'image-description'}>
                      { videoText && Parser(videoText) }
                    </div>

                    <a href={getAside(this.props, 'fullVideoUrl')} className={'video-url'}>
                      Guarda il video completo
                    </a>

                    <div className={'content'} dangerouslySetInnerHTML={{ __html: body }}/>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </section>
    )
  }
}
