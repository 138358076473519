import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { setNextCategoryActive, setPrevCategoryActive } from '../../actions/categories'
import { getCategoriesWithRelations} from '../../selectors'
import { navigateToPage } from '../../helpers'
import { withRouter } from 'react-router-dom'
import { Glyphicon } from 'react-bootstrap'
import { getPeriodsYears, flattenPeriodLocations, getYears, getPeriods } from '../../selectors/periods'
import { periodsSetYear, periodsSetDecade } from '../../actions/periods'
import 'core-js/fn/array/find-index'

const getYearsClassName = (active = false, disabled = false) => {
  const className = []

  if(active){
    className.push('active-year')
  }

  if(disabled){
    className.push('disabled')
  }

  return className.join(' ')
}

class BottomBar extends Component {
  constructor (props) {
    super(props)

    this._nextContent = this._nextContent.bind(this)
    this._prevContent = this._prevContent.bind(this)
  }

  _getIndexOfCurrentContent () {
    const {currentContentPermalink, contents} = this.props

    const index = contents.findIndex(({permalink}) => permalink === currentContentPermalink)

    if(typeof index !== 'undefined'){
      return index
    }

    return 1
  }

  _nextContent (e) {
    e.preventDefault()

    const {contents} = this.props
    const currentIndex = this._getIndexOfCurrentContent()

    const {permalink, type} = currentIndex === contents.length - 1 ? contents[0] : contents[currentIndex + 1]

    return navigateToPage(e, `/${type.permalink}/${permalink}`)
  }

  _prevContent (e) {
    e.preventDefault()

    const {contents} = this.props
    const currentIndex = this._getIndexOfCurrentContent()

    const {permalink, type} = currentIndex <= 0 ? contents[contents.length - 1] : contents[currentIndex - 1]

    return navigateToPage(e, `/${type.permalink}/${permalink}`)
  }

  _setActiveDecade(decade){
    const { activeDecade, periodsSetDecade } = this.props

    if(activeDecade === decade){
      return periodsSetDecade(null)
    }

    return periodsSetDecade(decade)
  }

  _periodsSetYear(year, disabled = false){
    if(!disabled) return this.props.periodsSetYear(year)
  }

  render () {
    const years = getYears(1900, 1999)
    const { contents } = this.props

    return (
      <div className="bottom-bar">
        {
          contents.length > 0 && (
            <Fragment>
              <a className="primary-button prev-content" onClick={this._prevContent}>
                <Glyphicon glyph="chevron-up"/>
              </a>
              <a className="primary-button next-content" onClick={this._nextContent}>
                <Glyphicon glyph="chevron-down"/>
              </a>
            </Fragment>
          )
        }
        <div className = { 'timeline' }>
          <div className={'line'}/>
          <ul className={this.props.activeDecade && 'active'}>
            {
              Object.entries(years).map(([decadeName, years], index) => {
                const isActive = decadeName === this.props.activeDecade

                return(
                  <li
                    className={isActive ? 'category-bar active-decade' : 'category-bar'}
                    key={index}
                  >
                    {
                      decadeName === this.props.activeDecade && (
                        <ul className={ "years" }>
                          {
                            years.map((year, index) => {
                              const active = year === this.props.activeYear
                              const disabled = this.props.allowedYears.indexOf(year) < 0

                              return (
                                <li
                                  key={year}
                                  onClick={() => this._periodsSetYear(year, disabled)}
                                  className={ getYearsClassName(active, disabled) }
                                >
                                  <a>{ index === 0 ? year : `'${year.substring(year.length - 2)}`}</a>
                                </li>
                              )
                            })
                          }
                        </ul>
                      )
                    }
                    {
                      !isActive &&
                      <a className={'decade'} onClick={() => this._setActiveDecade(decadeName)}>{decadeName}</a>
                    }
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const splittedPath = ownProps.location.pathname.split('/')
  const activeCategoryPermalink = splittedPath[1]
  const currentContentPermalink = splittedPath[2]
  const categoriesWithRelations = getCategoriesWithRelations(state)
  const activeCategory = categoriesWithRelations.filter(({permalink}) => permalink === activeCategoryPermalink)[0]
  const list = activeCategory ? activeCategory.contents : []
  const currentPage = activeCategory && state.contents.data && state.contents.data.filter(({permalink}) => permalink === activeCategoryPermalink)[0]
  const [min, max] = currentPage ? currentPage.asides.data.filter(({handler}) => handler === 'pageDate')[0].body.split('-') : []
  const tags = list.reduce((accumulator, {tags}) => {
    return tags ? [
      ...accumulator,
      ...tags.data.map(({label}) => label)
    ] : accumulator
  }, []).filter(tag => tag >= min && tag <= max).filter((item, index, arr) => arr.indexOf(item) === index).sort()
  const categories = getCategoriesWithRelations(state)
  const { activeDecade, year: activeYear } = getPeriods(state)
  const contents = flattenPeriodLocations(state).filter(({ type }) => type.permalink === activeCategoryPermalink)

  return ({
    list,
    categories,
    currentContentPermalink,
    filteredContents: state.filteredContents,
    activeCategory: activeCategoryPermalink,
    tags,
    years: getPeriodsYears(state),
    activeDecade,
    activeYear,
    allowedYears: getPeriodsYears(state),
    contents
  })
}

const mapDispatchToProps = dispatch => ({
  setNextCategoryActive: () => dispatch(setNextCategoryActive),
  setPrevCategoryActive: () => dispatch(setPrevCategoryActive),
  periodsSetYear: year => dispatch(periodsSetYear(year)),
  periodsSetDecade: decade => dispatch(periodsSetDecade(decade))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BottomBar))
