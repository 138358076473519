import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setHTML,getConfig } from './../../helpers'
import { Grid, Row, Col, FormGroup, FormControl, ControlLabel, Button, Alert } from 'react-bootstrap'

import {
  getContactsContents
} from './../../selectors'

const apiUrl = getConfig().apiUrl

const mapStateToProps = (state, ownProps) => ({
  contents: getContactsContents(state)
})

class Contacts extends Component {

  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      message: '',
      resultMessage: null,
    };

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.showEmailMessage = this.showEmailMessage.bind(this)
  }

  onChange = (e) => {
    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = this.state;

    fetch(`${apiUrl}/mailer/ses/send`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title: 'Nuovo messaggio dal sito web da ' + name,
        message: message + '<br /><br />Messaggio inviato da: ' + name + '<br />Email: ' + email,
        toAddresses: ['info@abicoop-re.it']
      })
    }).then((response) => this.setState({
        resultMessage: response.ok
      })
    )
  }

  showEmailMessage() {
    return this.state.resultMessage === true ? <Alert bsStyle="success">Email inviata con successo.</Alert> : this.state.resultMessage === false ? <Alert bsStyle="danger">Si è verificato un errore nell'invio della mail.</Alert> : null
  }

  render() {
    const {contents} = this.props
    const asides = this.props.contents.asides

    return (
      <div className="page contacts">
        <h1 className='h2 decorated' dangerouslySetInnerHTML={setHTML(contents.title)}/>
        <Grid>
          <Row>
            <Col xs={12} sm={4} className="contacts-intro">
              <div dangerouslySetInnerHTML={setHTML(contents.body)}/>
            </Col>
            <Col xs={12} sm={7} smPush={1}>
              <form id="contatti" method="post" onSubmit={this.onSubmit}>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel>{asides.nameFieldLabel}</ControlLabel>
                  <FormControl
                    name="name"
                    type="text"
                    placeholder={`${asides.nameFieldPlaceholderValue}*`}
                    onChange={this.onChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel>{asides.emailFieldLabel}</ControlLabel>
                  <FormControl
                    name="email"
                    type="text"
                    placeholder={`${asides.emailFieldPlaceholderValue}*`}
                    onChange={this.onChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>
                <FormGroup
                  controlId="formBasicText"
                >
                  <ControlLabel>{asides.messageFieldLabel}</ControlLabel>
                  <FormControl
                    name="message"
                    componentClass="textarea"
                    placeholder={`${asides.messageFieldPlaceholderValue}`}
                    onChange={this.onChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>
                <Button bsStyle="default" bsSize="large" type="submit">
                  {asides.submitButtonText}
                </Button>
              </form>
              <div>
                { this.showEmailMessage() }
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(Contacts)
