import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { maps } from './Maps'

export class SectionVectorMap extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired
  }

  render() {
    const { id, map } = this.props

    if(map || Object.prototype.hasOwnProperty.call(maps, id)) {
      return <img src={ map || maps[id] } className = { "vector-map" } alt="Map" />
    }

    return null
  }
}

export default SectionVectorMap
