import React, { Component } from "react"
import { Grid, Row, Col, Carousel, CarouselItem } from "react-bootstrap"

import { getAside, getAttachmentUrlFromAsides, getMetaFromAsides } from "../../../helpers/contents";
import { isObject } from "../../../helpers/contents";

import SectionFullwidthImage from './SectionFullwidthImage'
import { Image } from "../../Image/index";
import Parser from "html-react-parser";

const getSectionImages = section => {
  return Object.entries(section)
    .reduce((accumulator, [name, body]) => {
      if(name.includes('image') && !name.includes('Style') && isObject(body) && body.type === 'single-line-text'){
        const image = getAttachmentUrlFromAsides(section, body.handler)
        const meta = getMetaFromAsides(section, body.handler)

        if(image){
          return [
            ...accumulator,
            <CarouselItem key={name}>
              <Image color={getAside(section, 'imagesStyle')} src={ image } alt={ meta.alt }/>
            </CarouselItem>
          ]
        }
      }

      return accumulator
    }, [])
}

const getColsData = section => {
  const layout = getAside(section, 'imagesLayout')

  switch(layout){
    case "1":
      return {
        className: 'portrait',
        left: 7,
        right: 5
      }
    case "0":
    default:
      return {
        className: 'landscape',
        left: 12,
        right: 11
      }
  }
}

export class LayoutE extends Component {

  render() {

    const {
      ...section
    } = this.props

    const imagesText = getAside(section, 'imagesText')
    const layout = getAside(section, 'imagesLayout')

    if(layout === "2") return <SectionFullwidthImage {...section} imageText={imagesText}/>

    const sectionImages = getSectionImages(section)
    const colsData = getColsData(section)

    return (
      <section className="page-section section-image-with-text">
        <Grid className={ "no-gutter" } fluid>
          <Row>
            <Col md={7} mdOffset={4} className={colsData.className}>
              <Row>
                <Col sm={colsData.left}>
                  <div className={'image-wrap'}>
                    <Carousel indicators={false} controls={sectionImages.length >= 2}>
                      {sectionImages}
                    </Carousel>
                  </div>
                </Col>
                {
                  colsData.className === 'portrait' ?
                  <Col sm={colsData.right} className={'images'}>
                    <div className="content">
                      { section && section.body && Parser(section.body) }
                      <div className = { "image-description" }>
                        { imagesText && Parser(imagesText) }
                      </div>
                    </div>
                  </Col> :
                  <Col sm={colsData.right} className={'images'}>
                    <div className = { "image-description" }>
                      { imagesText && Parser(imagesText) }
                    </div>
                    <div className="content">
                      { section && section.body && Parser(section.body) }
                    </div>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </Grid>
      </section>
    )
  }
}
