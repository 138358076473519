import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Spinner = props => {
  if(props.show){
    return (
      <div
        className={classNames(
          'spinner-wrapper',
          props.customClass
        )}
      >
        <div className="spinner"/>
      </div>
    )
  }

  return null
}

Spinner.propTypes = {
  show: PropTypes.bool.isRequired
}

export default Spinner
