import { APP_LOADING } from '../actions/app'

const APP_INITIAL_STATE = {
  loading: true
}

export const app = (state = APP_INITIAL_STATE, action) => {
  switch(action.type){
    case APP_LOADING:
      return Object.assign({}, state, {
        loading: action.loading
      })
    default:
      return state
  }
}
