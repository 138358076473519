import React from 'react'
import PropTypes from 'prop-types'

const getColorClass = ({ color }) => {
  const baseClass = 'img-responsive '

  switch(color){
    case "1":
      return baseClass + 'img-desaturated'
    case "2":
      return baseClass + 'img-grayscale'
    default:
      return baseClass
  }
}

const mergeClassNames = props => {
  const colorClass = getColorClass(props)

  if(props.hasOwnProperty('className')){
    return props.className + ' ' + colorClass
  }

  return colorClass
}

export const Image = ({ color, ...props }) => {
  if(!props.src) return null

  return <img {...props} className={mergeClassNames({ color, ...props })}/>
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  color: PropTypes.string
}

Image.defaultProps = {
  color: "0"
}
