import { createSelector } from 'reselect'
import {navigateToPage, getFeaturedImage, mergeAsides} from '../helpers'
import { getContents } from './contents'
import { extractData, setAttachByPermalink } from '../helpers'
import {getAttachmentUrl} from '../helpers/contents'
import { getCategories } from './categories'


export const getSelectedLang = (state) => state.selectedLang

export const getContentsOld = (state) => {
  if (state.contents.data.length) {
    return state.contents.data.map(item => {
      item.featuredImage = getFeaturedImage(item.attachments)
      return item
    })
  }

  return state.contents.data
}

export const getContentsWithRelations = createSelector(
  getContentsOld,
  getCategories,
  (contents, categories) => {
    if (!categories) return
    return contents.map(content => {
      if (content.asides && content.categories) {
        return Object.assign(
          {},
          content,
          {
            handleClick: (e, href) => navigateToPage(e, `/${content.permalink}`)
          },
          {
            href: `/${content.permalink}`
          },
          {
            asides: content.asides && content.asides.data ? content.asides.data.reduce(
              (acc, cur) => Object.assign(
                {},
                acc,
                { [cur.handler]: cur.body }
              ), {}) : {}
          },
          {
            categories: content.categories && content.categories.data ? content.categories.data.reduce(
              (acc, cur) => Object.assign(
                {},
                acc,
                {
                  [cur.id]: {
                    label: cur.label,
                    permalink: cur.permalink
                  }
                }
              ), {}) : {}
          }
        )
      } else if (content.asides && !content.categories) {
        return Object.assign(
          {},
          content,
          {
            handleClick: (e, href) => navigateToPage(e, `/${content.permalink}`)
          },
          {
            href: `/${content.permalink}`
          },
          {
            asides: content.asides && content.asides.data && content.asides.data.reduce(
              (acc, cur) => Object.assign(
                {},
                acc,
                { [cur.handler]: cur.body }
              ), {}
            )
          },
          { categories: null }
        )
      } else if (!content.asides && content.categories) {
        return Object.assign(
          {},
          content,
          {
            handleClick: (e, href) => navigateToPage(e, `/${content.permalink}`)
          },
          {
            href: `/${content.permalink}`
          },
          { asides: null },
          {
            categories: content.categories && content.categories.data && content.categories.data.reduce(
              (acc, cur) => Object.assign(
                {},
                acc,
                {
                  [cur.id]: {
                    label: cur.label,
                    permalink: cur.permalink
                  }
                }
              ), {}
            )
          }
        )
      } else {
        return Object.assign(
          {},
          content,
          {
            handleClick: (e, href) => navigateToPage(e, `/${content.permalink}`)
          },
          {
            href: `/${content.permalink}`
          }
        )
      }
    })
  }
)

export const getCategoriesWithRelations = createSelector(
  getContentsOld,
  getCategories,
  (contents, categories) => {
    if (!categories) return []
    if (!contents) return []
    return categories.data.map(item => {
      item.contents = []
      contents.forEach(content => {
        if (content.categories) {
          content.categories.data.forEach(category => {
            if (category.permalink === item.permalink) item.contents.push(content)
          })
        }
      })
      return item
    })
  }
)

export const getHomeContents = createSelector(
  getContents,
  (contents) => {
    const content = contents.data.find(content => content.id === 1)

    if(content) return setAttachByPermalink(extractData(content))

    return {}
  }
)

export const getAboutContents = createSelector(
  getContents,
  (contents) => {
    const content = contents.data.find(content => content.id === 28)

    if(content) {
      return setAttachByPermalink(mergeAsides(extractData(content)))
    }

    return {}
  }
)

export const getAboutSlideshowContents = state => {
  const { attachments, ...contents } = getAboutContents(state)

  if(!attachments || !contents) return []
  const slides = Object.entries(contents).reduce((accumulator, [name, data]) => {
    if(name.includes('slide') && name.includes('Image')){
      return [
        ...accumulator,
        getAttachmentUrl(attachments[data.body])
      ]
    }

    return accumulator
  }, [])

  if(!slides) return []

  return slides
}

export const getContactsContents = createSelector(
  getContentsWithRelations,
  getSelectedLang,
  (contents, selectedLang) =>
    contents.find(content => content.id === 6 && content.lang === selectedLang)
)

export const getSlideshowContents = state => {
  const { asides, attachments } = getHomeContents(state)

  if(!asides || !attachments) return []

  const slides = asides.reduce((accumulator, {handler, body}) => {
    if(handler.includes('slide') && handler.includes('Image')){
			const [index] = handler.match(/\d+/)

			accumulator[index] = getAttachmentUrl(attachments[body])
    }

    return accumulator
  }, [])

  if(!slides) return []

  return slides
}

export const getBlogArticles = createSelector(
  getContentsWithRelations,
  getSelectedLang,
  (contents, selectedLang) => {
    const langCode = selectedLang !== 'it' ? '/en' : ''
    return contents
      .filter(content => content.type.data.id === 3 && content.lang === selectedLang)
      .map(articolo =>
        Object.assign(
          {},
          articolo,
          { href: `${langCode}/news/${articolo.permalink}` },
          { handleClick: (e, href) => navigateToPage(e, `${langCode}/news/${articolo.permalink}`) }
        )
      )
  }
)
