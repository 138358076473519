import {getPeriods} from "../selectors/periods";

export const PERIODS_FETCHING = 'PERIODS_FETCHING'

const periodsFetching = fetching => ({
  type: PERIODS_FETCHING,
  fetching
})

export const PERIODS_ERROR = 'PERIODS_ERROR'

const periodsError = error => ({
  type: PERIODS_ERROR,
  error
})

export const PERIODS_DATA = 'PERIODS_DATA'

const periodsData = data => ({
  type: PERIODS_DATA,
  data
})

export const fetchPeriods = () => (dispatch, getState, api) => {
  dispatch(periodsFetching(true))

  const state = getState()
  const skip = getPeriods(state).data.length

  return api.get(`/contents/types?params=${JSON.stringify({ skip, where: { id: { operator: '>', value: 4 } } })}`)
    .then(({ data }) => {
      return dispatch(periodsData(data))
    })
    .catch(() => dispatch(periodsError(true)))
    .then(() => dispatch(periodsFetching(false)))
}

export const PERIODS_SET_YEAR = 'PERIODS_SET_YEAR'

export const periodsSetYear = year => ({
  type: PERIODS_SET_YEAR,
  year
})

export const PERIODS_SET_DECADE = 'PERIODS_SET_DECADE'

export const periodsSetDecade = activeDecade => ({
  type: PERIODS_SET_DECADE,
  activeDecade
})
