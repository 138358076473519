import React, {Component, Fragment} from 'react'
import { Button, Glyphicon } from 'react-bootstrap'

export class Audio extends Component{
  state = { playing: false }

  _handleAudio = () => {
    const { playing } = this.state
    const { id } = this.props
    const audioElement = document.getElementById(id)

    if(audioElement){
      this.setState({ playing: !playing })

      if(playing){
        audioElement.pause()
      } else {
        audioElement.play()
      }
    }
  }

  render(){
    const { playing } = this.state
    const { id, audio } = this.props

    return(
      <Fragment>
        <Button className = { "audio-toggle" } onClick={ this._handleAudio }>
          <Glyphicon glyph={ playing ? "pause" : "play" } />
        </Button>
        <audio id={id} src={audio}>
          Il tuo browser non supporta l'elemento <code>audio</code>.
        </audio>
      </Fragment>
    )
  }
}
