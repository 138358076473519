import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Glyphicon } from 'react-bootstrap'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { getContents } from '../../selectors/contents'
import { parseQueryString } from '../../helpers'

const mapStateToProps = (state, { parentPermalink, location }) => {
  const contents = getContents(state)
  const page = contents.data.find(content => content.permalink === parentPermalink)

  const { preview } = parseQueryString(location.search)

  return {
    hasPage: !!page,
		status: page && page.status,
    featured: (page && !!page.featured) || !!preview,
    fetchingContents: contents.fetching
  }
}

class SideCategory extends Component{
  render(){
    const {
      active,
      label,
      parentPermalink,
      activePage,
      contents,
      periodsAreFilteredByYear,
      handleClick,
      fetchingContents,
      featured,
      location,
      hasPage,
			status,
    } = this.props

    if(periodsAreFilteredByYear && (!featured || !contents.length)) return null

    const [periodName, date] = label.split('/')

    return (
      <li
        className={classNames(
          periodsAreFilteredByYear && 'year-only'
        )}
      >
        <Link
          to={{
            pathname: active && !activePage ? '/' : `/${parentPermalink}`,
            search: location.search
          }}
          className={classNames(
            !fetchingContents && !featured && !status && 'disabled',
            active && !periodsAreFilteredByYear && 'active'
          )}
          onClick={handleClick}
        >
          {active && !periodsAreFilteredByYear && (
            <Glyphicon
              glyph={'menu-left'}
              style={{ margin: '1px 10px 0 0', fontSize: '18px' }}
            />
          )}
          <span>
            { !periodsAreFilteredByYear && periodName}
            <div className="date">
              {date}
            </div>
          </span>
        </Link>
        <Categories
          active={active}
          periodsAreFilteredByYear={periodsAreFilteredByYear}
          contents={contents}
          parentPermalink={parentPermalink}
          activePage={activePage}
          handleClick={handleClick}
          loading={fetchingContents}
          featured={featured}
          location={location}
          hasPage={hasPage}
        />
      </li>
    )
  }
}

export default withRouter(
  connect(mapStateToProps)(SideCategory)
)

const Categories = ({
  active,
  periodsAreFilteredByYear,
  contents,
  parentPermalink,
  activePage,
  handleClick,
  loading,
  featured,
  location,
  hasPage
}) => {
  if(!active && !periodsAreFilteredByYear) return null

  if(featured && contents && contents.length){
    return (
      <ul>
        {
          contents.map(({label, permalink, published = false}) => (
            <li key={label}>
              <Link
                to={{
                  pathname: `/${parentPermalink}/${permalink}`,
                  search: location.search
                }}
                className={classNames(
                  permalink === activePage && 'active',
                  !published && !featured && 'disabled',
                )}
                onClick={handleClick}
              >
                {label}
              </Link>
            </li>
          ))
        }
      </ul>
    )
  }

  if(active && loading && (!hasPage || featured)) {
    return 'Caricamento...'
  }

  return (
    <ul>
      <li>
        <span className={'coming-soon'}>Coming soon</span>
      </li>
    </ul>
  )
}
