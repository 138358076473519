import { getConfig} from '../helpers'
import { appLoadingStatus } from './app'
import { createContentRequestOptions } from "./contents/server";

export const apiUrl = getConfig().apiUrl

export const makeSingleContentRequest = (fetcher, selector) => {
  return properties => (dispatch, getState) => {
    const state = getState()
    const contents = selector(state)
    const skip = contents.data.length

    return dispatch(fetcher(skip, createContentRequestOptions(properties)))
      .then(() => dispatch(appLoadingStatus(false)))
  }
}

export const makeRequestForRemainingContents = fetcher => properties => dispatch => {
  const backgroundContentsOptions = {
    where: Object.entries(properties).reduce((accumulator, [name, value]) => ({
      ...accumulator,
      [name]: {
        operator: '!=',
        value
      }
    }),{})
  }

  return dispatch(fetcher(0, backgroundContentsOptions))
}

//TODO: remove

export const receiveContents = (error, data) => {
  return ({
    type: 'RECEIVE_CONTENTS',
    error,
    data
  })
}

export const setLanguage = (lang) => ({
  type: 'SET_LANGUAGE',
  lang
})
