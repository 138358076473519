import { getSizeUrl } from './index'

export const isObject = object => {
  return object && object.constructor === Object
}

export const isArray = array => {
  return array && array.constructor === Array
}

export const getAside = (data, handler) => {
  if(isObject(data) && data.hasOwnProperty(handler)){
    return data[handler].body
  }
}

export const getAttachmentFromAsides = (data, handler) => {
  if(isObject(data) && data.hasOwnProperty(handler) && data.hasOwnProperty('attachments')){
    return data.attachments[data[handler].body]
  }
}

export const getAttachmentUrl = attachment => {
  if(attachment) return getSizeUrl(attachment.url, 'md', 'lg', 'xl', 'lg')
}

export const getAttachmentUrlFromAsides = (data, handler) => {
  const attachment = getAttachmentFromAsides(data, handler)

  if(attachment){

    if(attachment.type.includes('image')) return getSizeUrl(attachment.url, 'md', 'lg', 'xl', 'lg')

    return attachment.url.document
  }
}

export const getMetaFromAsides = (data, handler) => {
  const attachment = getAttachmentFromAsides(data, handler)

  if( attachment && attachment.alt ) {
    return {
      'title': attachment.title,
      'alt': attachment.alt
    }
  } else {
    return {
      'title': handler,
      'alt': handler
    }
  }
}

export const getTitleAndSubtitle = html => {
  if(html){
    const newline = /\r?\n/
    const [title, ...subtitle] = html.split(newline)

    return {
      title,
      subtitle: subtitle.join('\r\n').replace('<br>', '\r\n')
    }
  }

  return {}
}
