import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'

import SideCategory from '../SideCategory'
import { getPeriods } from "../../selectors/periods"

const _Body = ({ periods, handleClick, activePage, activeCategory, loading, periodsAreFilteredByYear }) => (
  <Row>
    <Col>
      <ul>
        {
          periods.map(({ label, permalink, locations }) => {
            if((activeCategory || activePage) && permalink !== activeCategory && !periodsAreFilteredByYear) return null

            return (
              <SideCategory
                label={label}
                parentPermalink={permalink}
                contents={locations}
                key={permalink}
                active={activeCategory === permalink}
                handleClick={handleClick}
                activePage={activePage}
                loading={loading}
                periodsAreFilteredByYear={periodsAreFilteredByYear}
              />
            )
          })
        }
      </ul>
    </Col>
  </Row>
)

const mapStateToProps = (state) => ({
  periodsAreFilteredByYear: !!getPeriods(state).year
})

export const Body = connect(mapStateToProps)(_Body)
