import { CATEGORIES_DATA, CATEGORIES_ERROR, CATEGORIES_FETCHING } from '../actions/categories'

const CATEGORIES_INITIAL_STATE = {
  data: [],
  fetching: false,
  error: false
}

export const categories = (state = CATEGORIES_INITIAL_STATE, action) =>  {
  switch(action.type){
    case CATEGORIES_DATA:
      return Object.assign({}, state, {
        data: [...state.data, ...action.data]
      })
    case CATEGORIES_ERROR:
      return Object.assign({}, state, {
        error: action.error
      })
    case CATEGORIES_FETCHING:
      return Object.assign({}, state, {
        fetching: action.fetching
      })
    default:
      return state
  }
}
