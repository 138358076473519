import React, { Component } from 'react'
import { connect } from 'react-redux'
import { mergeAsides, extractData, setAttachByPermalink, getSizeUrl } from '../../helpers'
import { getAside, getAttachmentFromAsides, getAttachmentUrlFromAsides } from '../../helpers/contents'
import { getContents } from '../../selectors/contents'
import { fetchSingleContent, getLoadContentOptionsByPathname } from '../../actions/contents'

import { MetaHelmet } from '../MetaHelmet'
import Map from '../Map'

import InnerPagesSlider from '../InnerPagesSlider'
import { LayoutA } from '../Layouts/LayoutA'
import { Media } from '../Layouts/Media'
import { LayoutE } from '../Layouts/LayoutE'
import { LayoutD } from '../Layouts/LayoutD'
import { fetchSingleCategory, getLoadCategoriesOptionsByPathname } from '../../actions/categories'

const mapApiKey = "AIzaSyBRwWg3N0d3REyxEB9XKijAj_OrjxlRkzc"

const getSliderContent = master => {
  return Object.keys(master)
    .reduce((accumulator, prop) => {
      if(prop.includes('slide') && prop.includes('Image')){
        return {
          images: [
            ...accumulator.images,
            getAttachmentUrlFromAsides(master, prop)
          ],
          titles: [
            ...accumulator.titles,
            getAside(master, prop.slice(0, 6) + 'Text')
          ]
        }
      }

      return accumulator
    }, { images: [], titles: [] })
}

class Content extends Component {
  _getLocationMap() {
    const locationMap = getAttachmentFromAsides(this.props.content[0], 'locationMap')

    if(locationMap && Object.prototype.hasOwnProperty.call(locationMap, 'url')) {
      return getSizeUrl(locationMap.url, 'md', 'lg', 'xl', 'lg')
    }
  }

  render () {
    const { match, content, blend } = this.props

    if(!content.length){
      return <div style={{ backgroundColor: 'lightgray', height: '100vh', width: '100vw' }}/>
    }

    const [ master, ...sections ] = content

    const category = master.categories.find(({ permalink }) => permalink === match.params.pageId)
    const slider = getSliderContent(master)

    return (
      <div className={'inner-pages page ' + match.params.pageId}>
        <MetaHelmet metaTitle={master.metaTitle} metaDescription={master.metaDescription}/>
        <InnerPagesSlider
          id={master.id}
          title={category.label}
          subtitle={master.body}
          date={getAside(master, 'date')}
          images={slider.images}
          titles={slider.titles}
          blend={blend}
          locationMap={this._getLocationMap()}
        />
        {
          sections.map(({ layoutId, ...section }) => {
            switch (layoutId) {
              case 1:
                return <LayoutA {...section} key={section.permalink}/>
              case 2:
                return <Media {...section} type={'pdf'} key={section.permalink}/>
              case 3:
                return <Media {...section} type={'audio'} key={section.permalink}/>
              case 4:
                return <LayoutD {...section} key={section.permalink}/>
              case 5:
                return <LayoutE {...section} key={section.permalink}/>
              default:
                return null
            }
          })
        }
        <Map
          googleMapURL = { `https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&v=3.exp&libraries=geometry,drawing,places` }
          containerElement = {
            <div className = { "page-map" } />
          }
          loadingElement = { <p>Loading</p> }
          mapElement = { <div style={{ height: "100%" }} /> }
          coordinates={getAside(master, 'coordinates')}
        />
      </div>
    )
  }
}

Content.serverFetch = pathname => dispatch => Promise.all([
  dispatch(fetchSingleContent(getLoadContentOptionsByPathname(pathname))),
  dispatch(fetchSingleCategory(getLoadCategoriesOptionsByPathname(pathname)))
])

const getContent = (state, ownProps) => {
  const { data } = getContents(state)

  return data
    .filter(({ categories }) => {
      return categories.data.some(({ permalink }) => permalink === ownProps.match.params.pageId)
    })
    .sort((a, b) => a.permalink.localeCompare(b.permalink))
    .map(content => setAttachByPermalink(mergeAsides(extractData(content))))
}

const getBlend = (state, ownProps) => {
  const { data } = getContents(state)
  const content = data.find(({ permalink }) => permalink === ownProps.match.params.categoryId)

  if(content){
    return getAttachmentUrlFromAsides(
      setAttachByPermalink(mergeAsides(extractData(content))),
      'blend'
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    content: getContent(state, ownProps),
    blend: getBlend(state, ownProps)
  }
}

export default connect(mapStateToProps)(Content)
